import UploadApi from "../services/UploadApi";

class General
{
    siteTitle = "Sports Diet";

    host = window.location.hostname;
    siteUrl = (this.host === "localhost" || this.host === "127.0.0.1") ? "http://127.0.0.1:8000" : "https://api.testdomainwebna.com";
    // siteUrl = (this.host === "localhost" || this.host === "127.0.0.1") ? "http://127.0.0.1/mohan21/shirazi/sports-diet/sports-diet-backend" : "https://api.testdomainwebna.com";
    apiUrl = this.siteUrl + "/api/pa";

    //
    localStorageName = "authenticatePA";

    // token
    authorizationToken = "";
    authorizationName = "";
    admin = null;
    isRemember = false;

    // order status
    orderStatusUnpaid = 1;
    orderStatusInProgress = 2;
    orderStatusCompleted = 3;

    // init status class
    initStatusClass = (status) =>
    {
        return status.replace(/ /g, '-').toLowerCase();
    };

    // init pageNumber
    initPageNumber = (paginationData) =>
    {
        let pageNumber = paginationData.currentPageNumber;

        if(paginationData.currentPageRows - 1 === 0)
        {
            pageNumber = (paginationData.currentPageNumber > 1) ? pageNumber - 1 : 0;
        }

        return pageNumber;
    };

    // check link
    isLink = (str) =>
    {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        return !!pattern.test(str);
    };

    //
    getDomain = (url) =>
    {
        try
        {
            const { hostname } = new URL(url);

            return hostname;
        }
        catch (e)
        {
            return '';
        }
    };

    //
    priceFormat(price)
    {
        price = (price !== '') ? price.toString().replace(/,/g, '') : 0;
        return (price > 0) ? new Intl.NumberFormat().format(price) : "";
        // return (price > 0) ? price.toLocaleString('fa-IR') : "";
    }

    // editor
    editorInit =
        {
            height: 400,
            menubar: false,

            plugins: ['preview', 'powerpaste', 'casechange', 'importcss', 'tinydrive', 'searchreplace', 'autolink', 'autosave', 'save', 'directionality', 'advcode', 'visualblocks', 'visualchars', 'fullscreen', 'image', 'link', 'media', 'mediaembed', 'codesample', 'table', 'charmap', 'pagebreak', 'nonbreaking', 'anchor', 'tableofcontents', 'insertdatetime', 'advlist', 'lists', 'checklist', 'wordcount', 'tinymcespellchecker', 'a11ychecker', 'editimage', 'help', 'formatpainter', 'permanentpen', 'pageembed', 'charmap', 'tinycomments', 'mentions', 'quickbars', 'linkchecker', 'emoticons', 'advtable', 'export', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'advtemplate'],
            toolbar: 'bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | forecolor backcolor | image media | link anchor | outdent indent | numlist bullist | ltr rtl | undo redo removeformat | preview fullscreen',
            // toolbar: 'undo redo | bold italic underline strikethrough | typography | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | inserttemplate addtemplate | outdent indent | numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags',

            font_family_formats: "Metronic Pro=MetronicPro,sans-serif; Iran Sans=IranSansX,sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",

            file_picker_types: 'file image media',
            file_picker_callback: function(callback, value, meta)
            {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.onchange = function()
                {
                    const file = input.files[0];

                    let params = new FormData();
                    params.append('file', file);
                    UploadApi.save(params).then
                    (
                        function(response)
                        {
                            if(response.status === 200)
                            {
                                callback(response.fileLink, {title: file.name});
                            }
                            else
                            {
                                alert(response.message !== '' ? response.message : "There is a Problem");
                            }
                        }
                    ).catch
                    (
                        function(error)
                        {
                            alert(error);
                        }
                    );
                };

                input.click();
            },
        }
}

export default new General();
