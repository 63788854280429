import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import OrderApi from "../../services/OrderApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Show.css";
import {Moneys, WalletMoney, MoneyAdd, Trash, AddSquare} from "iconsax-react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {useParams} from "react-router";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";

//--------------------------------------------------

function Show(props)
{
    // get id
    let { orderId } = useParams();

    // set page title & page path
    usePage().pageData({title: Menus.orders.title, activeMenu: Menus.orders.className});

    //
    const alertAction = useRef(); // init alert

    const [orderDataLoading, setOrderDataLoading] = useState(true);
    const [orderData, setOrderData] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState('en');

    const [formProgramDescription, setFormProgramDescription] = useState({'en': '', 'fa': ''});

    const foodItems = {'stephan': '', 'time': '', 'diet': '', 'supplement': ''};
    const [formProgramFood, setFormProgramFood] = useState({'en': [foodItems, foodItems, foodItems], 'fa': [foodItems, foodItems, foodItems]});

    const workoutItems =
        [
            {'title': 'Cardio', 'des': ''},
            {'title': 'Crunches', 'des': ''},
            {'title': 'Reverse cunch', 'des': ''},
            {'title': 'Russian twist', 'des': ''},
            {'title': 'Dumbbell chest press', 'des': ''},
            {'title': 'Chest fly machine', 'des': ''},
            {'title': 'Inclined chest press', 'des': ''},
        ];
    const [formProgramWorkout, setFormProgramWorkout] = useState({'en': {0: workoutItems, 1: workoutItems}, 'fa': {0: workoutItems, 1: workoutItems}});

    // load data
    const loadData = () =>
    {
        setOrderDataLoading(true); // show loading

        // get data
        OrderApi.show(orderId).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setOrderData(response.data);

                    setFormProgramDescription({...response.data.programDescription});
                    if(response.data.programFood !== null) setFormProgramFood({...response.data.programFood});
                    if(response.data.programWorkout !== null) setFormProgramWorkout({...response.data.programWorkout});
                }

                setOrderDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // send data to server
    const submitOrder = (status) =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params = {'formStatus': status, 'formProgramDescription': formProgramDescription, 'formProgramFood': formProgramFood, 'formProgramWorkout': formProgramWorkout};
        OrderApi.update(orderId, params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Order Updated", "success"); // show success

                    window.location.reload();
                }
                else
                {
                    alertAction.current.showAlert(response.message); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // handle tab
    const handleTabLanguageChange = (event, newValue) =>
    {
        setActiveLanguage(newValue);
    };

    // handle Description
    const handleDescription = (e) =>
    {
        let tmp = {...formProgramDescription};
        tmp[activeLanguage] = e.target.value;

        setFormProgramDescription({...tmp});
    };

    // handle Order Food
    const handleOrderFood = (e, index, itemName) => // set input value
    {
        let tmp = {...formProgramFood};
        let tmp2 = [...tmp[activeLanguage]];
        let tmp3 = {...tmp2[index]};

        tmp3[itemName] = e.target.value;
        tmp2[index] = {...tmp3};
        tmp[activeLanguage] = [...tmp2, ...[]];

        setFormProgramFood({...tmp});
    };

    const handleOrderFoodAddRow = () => // add row
    {
        let tmp = {...formProgramFood};
        let tmp2 = tmp[activeLanguage] !== undefined ? [...tmp[activeLanguage]] : [];

        tmp2[tmp2.length] = {...foodItems};
        tmp[activeLanguage] = [...tmp2, ...[]];

        setFormProgramFood({...tmp});
    };

    const handleOrderFoodRemoveRow = (index) => // remove row
    {
        let tmp = {...formProgramFood};
        let tmp2 = [...tmp[activeLanguage]];

        tmp2.splice(index, 1);
        tmp[activeLanguage] = [...tmp2, ...[]];

        setFormProgramFood({...tmp});
    };

    // handle Order Workout
    const handleOrderWorkout = (e, index, index2, itemName) => // set input value
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};
        let tmp3 = [...tmp2[index]];
        let tmp4 = {...tmp3[index2]};

        tmp4[itemName] = e.target.value;
        tmp3[index2] = {...tmp4};
        tmp2[index] = [...tmp3, ...[]];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleOrderWorkoutAddRow = () => // add row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};

        tmp2[parseInt(Object.keys(tmp2).pop()??0) + 1] = [...workoutItems];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleOrderWorkoutRemoveRow = (index) => // remove row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};

        delete tmp2[index];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleOrderWorkoutAddDayRow = (index) => // add row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};
        let tmp3 = [...tmp2[index]];

        tmp3[tmp3.length] = {...{'title': '', 'des': ''}};
        tmp2[index] = [...tmp3];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleOrderWorkoutRemoveDayRow = (index, index2) => // remove row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};
        let tmp3 = [...tmp2[index]];

        tmp3.splice(index2, 1);
        tmp2[index] = [...tmp3, ...[]];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    //
    return (
        <div className="page-content page-order-show">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Order </span>
                    <span className="title-t2">{orderId}</span>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    orderDataLoading ?
                        null
                        :
                        (
                            orderData != null ?
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4 col-data">
                                            <div className="data-title">Special</div>
                                            <div className="data-value">{orderData.special === 1 ? 'Specialized' : 'Non-Specialized'}</div>
                                        </div>

                                        <div className="col-4 col-data">
                                            <div className="data-title">Program Type</div>
                                            <div className="data-value">{orderData.foodWorkout === 1 ? 'Workout' : 'Food'}</div>
                                        </div>

                                        <div className="col-4 col-data">
                                            <div className="data-title">Training Program At</div>
                                            <div className="data-value">
                                                {
                                                    {
                                                        0: 'Home',
                                                        1: 'Gym',
                                                        2: 'Champion',
                                                    }[orderData.homeGym]
                                                }
                                            </div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Full Name</div>
                                            <div className="data-value">{orderData.name}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Email</div>
                                            <div className="data-value">{orderData.email}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Country</div>
                                            <div className="data-value">{orderData.country}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">City</div>
                                            <div className="data-value">{orderData.city}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Age</div>
                                            <div className="data-value">{orderData.age}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Gender</div>
                                            <div className="data-value">{orderData.gender === 1 ? 'Man' : 'Woman'}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Gain or Loss</div>
                                            <div className="data-value">{orderData.gainLoss === 1 ? 'Loss' : 'Gain'}</div>
                                        </div>

                                        {/*<div className="col-6 col-data">*/}
                                        {/*    <div className="data-title">Telegram ID</div>*/}
                                        {/*    <div className="data-value">{orderData.telegramID}</div>*/}
                                        {/*</div>*/}

                                        <div className="col-6 col-data">
                                            <div className="data-title">Instagram ID</div>
                                            <div className="data-value">{orderData.instagramID}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Height (cm)</div>
                                            <div className="data-value">{orderData.height}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Weight (kg)</div>
                                            <div className="data-value">{orderData.weight}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Blood Group</div>
                                            <div className="data-value">{orderData.bloodGroup}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Training in week (p/h)</div>
                                            <div className="data-value">{orderData.trainingInWeek}</div>
                                        </div>

                                        {/*<div className="col-6 col-data">*/}
                                        {/*    <div className="data-title">Disease Background</div>*/}
                                        {/*    <div className="data-value">{orderData.diseaseBackground ? 'Yes' : 'No'}</div>*/}
                                        {/*</div>*/}

                                        <div className="col-6 col-data">
                                            <div className="data-title">Smoke</div>
                                            <div className="data-value">{orderData.smoke === 1 ? 'Yes' : 'No'}</div>
                                        </div>

                                        <div className="col-12 col-data">
                                            <div className="data-title">Training history (any field)</div>
                                            <div className="data-value">{orderData.trainingHistory}</div>
                                        </div>

                                        <div className="col-12 col-data">
                                            <div className="data-title">Have you had any surgeries?</div>
                                            <div className="data-value">{orderData.surgeries}</div>
                                        </div>

                                        <div className="col-12 col-data">
                                            <div className="data-title">Food allergies</div>
                                            <div className="data-value">{orderData.foodAllergies}</div>
                                        </div>

                                        <div className="col-12 col-data">
                                            <div className="data-title">Disease Background Description</div>
                                            <div className="data-value">{orderData.diseaseBackgroundDes}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">Your Goal</div>
                                            <div className="data-value">{orderData.goal}</div>
                                        </div>

                                        <div className="col-6 col-data">
                                            <div className="data-title">More Detail</div>
                                            <div className="data-value">{orderData.moreDetail}</div>
                                        </div>

                                        <div className="col-12 col-data">
                                            <div className="data-title">Referral</div>
                                            <div className="data-value">{orderData.referral}</div>
                                        </div>
                                    </div>

                                    {/* image body */}
                                    <div className="row row-image-body">
                                        <div className="col-6"><a href={orderData.imageFront} className="image-box" target="_blank"><img src={orderData.imageFront} /> Picture Of The Front</a></div>
                                        <div className="col-6"><a href={orderData.imageBack} className="image-box" target="_blank"><img src={orderData.imageBack} /> Picture Of The Back</a></div>
                                        <div className="col-6"><a href={orderData.imageRightSide} className="image-box" target="_blank"><img src={orderData.imageRightSide} /> Picture Of The Right Side</a></div>
                                        <div className="col-6"><a href={orderData.imageLeftSide} className="image-box" target="_blank"><img src={orderData.imageLeftSide} /> Picture Of The Left Side</a></div>
                                    </div>

                                    {/* price */}
                                    <div className="row row-price">
                                        <div className="col-6"><a href={orderData.imageReceipt} className="image-box" target="_blank"><img src={orderData.imageReceipt} /> Picture Of The Receipt</a></div>

                                        <div className="col-12">
                                            <div className="price-box">
                                                <div className="item"><Moneys className="icon" /><div className="title-price"><span className="title">Price:</span><span className="price">{orderData.price} $<br />{orderData.priceToman} Toman</span></div></div>
                                                <div className="item"><MoneyAdd className="icon" /><div className="title-price"><span className="title">Tax({orderData.tax}%):<br />Tax Toman({orderData.taxToman}%):</span><span className="price">{orderData.priceTax} $<br />{orderData.priceTaxToman} Toman</span></div></div>
                                                <div className="item"><WalletMoney className="icon" /><div className="title-price"><span className="title">Total:</span><span className="price">{orderData.priceTotal} $<br />{orderData.priceTotalToman} Toman</span></div></div>
                                            </div>
                                        </div>

                                        {/*<div className="col-12">*/}
                                        {/*    <div className="price-box">*/}
                                        {/*        <div className="item"><Moneys className="icon" /><div className="title-price"><span className="title">Price:</span><span className="price">{orderData.priceToman} Toman</span></div></div>*/}
                                        {/*        <div className="item"><MoneyAdd className="icon" /><div className="title-price"><span className="title">Tax({orderData.tax}%):</span><span className="price">{orderData.priceTaxToman} Toman</span></div></div>*/}
                                        {/*        <div className="item"><WalletMoney className="icon" /><div className="title-price"><span className="title">Total:</span><span className="price">{orderData.priceTotalToman} Toman</span></div></div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>

                                    {/* order is specialize */}
                                    {
                                        orderData.special === 1 ?
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="page-description m-t-32"><h1>Program Data</h1></div>
                                                </div>

                                                <div className="col-12">
                                                    <Tabs className="tab-language" value={activeLanguage} onChange={handleTabLanguageChange}>
                                                        <Tab value="en" label="En" />
                                                        <Tab value="fa" label="Fa" />
                                                    </Tabs>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-input-box">
                                                        <label className="form-label">Description</label>
                                                        {
                                                            <textarea dir={activeLanguage === 'en' ? "ltr" : "rtl"} className="form-textarea no-icon h-100" value={formProgramDescription[activeLanguage]} onChange={(e) => handleDescription(e)}/>
                                                        }
                                                    </div>
                                                </div>

                                                {/* order is food or workout */}
                                                {
                                                    orderData.foodWorkout === 0 ?
                                                        <div className="col-12 order-list">
                                                            <div className="row row-head">
                                                                <div className="col-3">Stephan</div>
                                                                <div className="col-3">Time</div>
                                                                <div className="col-3">Diet</div>
                                                                <div className="col-3">Supplement</div>
                                                            </div>
                                                            {
                                                                formProgramFood[activeLanguage] !== undefined ?
                                                                    formProgramFood[activeLanguage].map
                                                                    (
                                                                        (item, index) =>
                                                                        {
                                                                            return (
                                                                                <div className="row row-body" key={index}>
                                                                                    <div className="col-3"><input className="form-input no-icon" value={item['stephan']} onChange={(e) => handleOrderFood(e, index, 'stephan')} /></div>
                                                                                    <div className="col-3"><input className="form-input no-icon" value={item['time']} onChange={(e) => handleOrderFood(e, index, 'time')} /></div>
                                                                                    <div className="col-3"><input className="form-input no-icon" value={item['diet']} onChange={(e) => handleOrderFood(e, index, 'diet')} /></div>
                                                                                    <div className="col-3"><input className="form-input" value={item['supplement']} onChange={(e) => handleOrderFood(e, index, 'supplement')} /></div>
                                                                                    {index > 0 ? <Trash className="btn-icon-delete" variant="Bulk" onClick={(e) => handleOrderFoodRemoveRow(index)} /> : null}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )
                                                                    :
                                                                    null
                                                            }
                                                            <div className="row row-body">
                                                                <div className="col-12"><AddSquare className="btn-icon-add" variant="Bulk" onClick={handleOrderFoodAddRow} /></div>
                                                            </div>
                                                        </div>
                                                        :
                                                        (
                                                            orderData.foodWorkout === 1 ?
                                                                <div className="col-12 order-list">
                                                                    {
                                                                        formProgramWorkout[activeLanguage] !== undefined ?
                                                                            Object.keys(formProgramWorkout[activeLanguage]).map
                                                                            (
                                                                                (key, index) =>
                                                                                {
                                                                                    return (
                                                                                        <div key={index}>
                                                                                            <div className="row row-head t2" key={index}>
                                                                                                <div className="col-12">Day {index + 1}</div>
                                                                                                {index > 0 ? <Trash className="btn-icon-delete" variant="Bulk" onClick={(e) => handleOrderWorkoutRemoveRow(key)} /> : null}
                                                                                            </div>
                                                                                            {
                                                                                                formProgramWorkout[activeLanguage][key].map
                                                                                                (
                                                                                                    (item2, index2) =>
                                                                                                    {
                                                                                                        return (
                                                                                                            <div className="row row-body" key={index2}>
                                                                                                                <div className="col-6"><input className="form-input no-icon" value={item2['title']} onChange={(e) => handleOrderWorkout(e, key, index2, 'title')} /></div>
                                                                                                                <div className="col-6"><input className="form-input no-icon" value={item2['des']} onChange={(e) => handleOrderWorkout(e, key, index2, 'des')} /></div>
                                                                                                                {index2 > 0 ? <Trash className="btn-icon-delete" variant="Bulk" onClick={(e) => handleOrderWorkoutRemoveDayRow(key, index2)} /> : null}
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            <div className="row row-body t2">
                                                                                                <div className="col-12"><AddSquare className="btn-icon-add" variant="Bulk" onClick={() => handleOrderWorkoutAddDayRow(key)} /></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="row row-body">
                                                                        <div className="col-12"><AddSquare className="btn-icon-add" variant="Bulk" onClick={handleOrderWorkoutAddRow} /></div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        )
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                :
                                <AlertDataEmpty />
                        )
                }


                {/* loading */}
                {formLoading || orderDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer">
                {
                    orderData !== null ?
                        <>
                            {orderData.orderStatus !== 3 ? <button onClick={() => submitOrder(1)} type="button" className="btn-custom t2 green">Accept</button> : null}
                            {orderData.orderStatus === 3 ? <button onClick={() => submitOrder(1)} type="button" className="btn-custom t2 green">Save Change</button> : null}
                            {orderData.orderStatus === 1 ? <button onClick={() => submitOrder(0)} type="button" className="btn-custom t2">Reject</button> : null}
                        </>
                        :
                        null
                }
            </div>
        </div>
    );
}

export default Show;
