import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Translate.css";
import {PercentageSquare, Trash} from "iconsax-react";
import TranslateApi from "../../services/TranslateApi";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {Editor} from "@tinymce/tinymce-react";
import General from "../../utils/General";

//--------------------------------------------------

function Translate(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.translate.title, activeMenu: Menus.translate.className});

    //
    const alertAction = useRef(); // init alert

    const [translateData, setTranslateData] = useState(null);
    const [translateDataLoading, setTranslateDataLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);

    const [formTaxDollar, setFormTaxDollar] = useState("");
    const [formTaxToman, setFormTaxToman] = useState("");
    const [formImageLogo, setFormImageLogo] = useState('');
    const [formImageLogoName, setFormImageLogoName] = useState('');
    const [formImageFavicon, setFormImageFavicon] = useState('');
    const [formImageFaviconName, setFormImageFaviconName] = useState('');

    const [activeLanguage, setActiveLanguage] = useState('en');

    // load data
    const loadData = () =>
    {
        setTranslateDataLoading(true); // show loading

        // get data
        TranslateApi.show().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setTranslateData(response.data);
                }

                setTranslateDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setTranslateDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // handle tab
    const handleTabLanguageChange = (event, newValue) =>
    {
        setActiveLanguage(newValue);
    };

    // handle translate
    const handleTranslate = (e, index) =>
    {
        let tmp = {...translateData};
        let tmp2 = {...tmp[activeLanguage]};

        tmp2[index] = e.target.value;
        tmp[activeLanguage] = {...tmp2, ...{}};

        setTranslateData({...tmp});
    };

    // send data to server
    const submitTranslate = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        let params = new FormData();
        params.append('data', JSON.stringify(translateData));

        TranslateApi.update(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Translate Updated", "success"); // show success
                }
                else
                {
                    alertAction.current.showAlert(response.message); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-translate">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Edit </span>
                    <span className="title-t2">Translate</span>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Tabs className="tab-language" value={activeLanguage} onChange={handleTabLanguageChange}>
                    <Tab value="en" label="En" />
                    <Tab value="fa" label="Fa" />
                </Tabs>

                <div className="container">
                    {
                        translateData !== null ?
                            Object.entries(translateData[activeLanguage]).map
                            (
                                (item, index) =>
                                {
                                    return (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-label">{item[0]}</div>
                                                <div className="form-input-box">
                                                    <input type="text" className="form-input" value={item[1]} dir={activeLanguage === "en" ? "ltr" : "rtl"} onChange={(e) => handleTranslate(e, item[0])} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )
                            :
                            <div>a</div>
                    }
                </div>

                {/* loading */}
                {formLoading || translateDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitTranslate} type="button" className="btn-custom t2">Save Change</button>
            </div>
        </div>
    );
}

export default Translate;
