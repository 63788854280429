import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useNavigate, useParams} from "react-router-dom";
import SortTable from "../../components/SortTable/SortTable";
import Pagination from "../../components/Pagination/Pagination";
import OrderApi from "../../services/OrderApi";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import General from "../../utils/General";
import IconButton from '@mui/material/IconButton';
import {Button, Container, Grid, TextField} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";
import AlertAction from "../../components/AlertAction";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import "./Orders.css";
import UserApi from "../../services/UserApi";
import {DollarSquare, Trash, Edit, ArrowRight2, Eye} from "iconsax-react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//--------------------------------------------------

function Orders(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.orders.title, activeMenu: Menus.orders.className});

    //
    const [orderData, setOrderData] = useState([]);
    const [orderDataLoading, setOrderDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const [orderId, setOrderId] = useState('');
    const [orderStatus, setOrderStatus] = useState('new');
    const [searchWord, setSearchWord] = useState('');

    const navigate = useNavigate();
    const alertAction = useRef(); // init alert
    const dialogQuestion = useRef(); // init alert delete

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setOrderData(null); // set data to null
        setOrderDataLoading(true); // show loading

        // get data
        OrderApi.list({ pageNumber: pageNumber, status: orderStatus }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setOrderData(response.data.list);
                }

                setOrderDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, [orderStatus]);

    //
    const handleOrderDelete = (id) =>
    {
        dialogQuestion.current.show();

        setOrderId(id); // set order id for delete
    };

    const orderDelete = () =>
    {
        dialogQuestion.current.hide(); // hide popup
        setOrderDataLoading(true); // show loading

        // get data
        OrderApi.destroy(orderId).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    alertAction.current.showAlert("Order Deleted", "success"); // show success

                    const pn = General.initPageNumber(paginationData);
                    if(pn > 0)
                    {
                        loadData(pn);
                    }
                    else
                    {
                        setOrderData(null);
                        setOrderDataLoading(false); // hide loading
                    }
                }
                else
                {
                    // alertAction.current.showAlert("Incorrect information"); // show error
                    alertAction.current.showAlert(response.message); // show error

                    setOrderDataLoading(false); // hide loading
                }
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-orders">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Order </span>
                    <span className="title-t2">List</span>
                </div>
            </div>

            <Tabs className="tab-language" value={orderStatus} onChange={(event, newValue) => setOrderStatus(newValue) }>
                <Tab value="new" label="New" />
                <Tab value="accept" label="Accepted" />
                <Tab value="reject" label="Rejected" />
            </Tabs>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    orderDataLoading ?
                        null
                        :
                        (
                            orderData !== null && orderData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th>User</th>
                                        {/*<th>Specialize</th>*/}
                                        <th>Type</th>
                                        <th>Gender</th>
                                        <th>Gain or Loss</th>
                                        <th>Training</th>
                                        <th>Price Dollar</th>
                                        <th>Price Toman</th>
                                        <th>Time</th>
                                        <th className="w-80"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        orderData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.user}</td>
                                                        {/*<td>{item.special === 1 ? 'Specialize' : 'Non-Specialize'}</td>*/}
                                                        <td>{item.foodWorkout === 1 ? 'Workout' : 'Food'}</td>
                                                        <td>{item.gender}</td>
                                                        <td>{item.gainLoss === 1 ? 'Loss' : 'Gain'}</td>
                                                        <td>
                                                            {
                                                                {
                                                                    0: 'Home',
                                                                    1: 'Gym',
                                                                    2: 'Champion',
                                                                }[item.homeGym]
                                                            }
                                                        </td>
                                                        <td>{item.price}</td>
                                                        <td>{item.priceToman}</td>
                                                        <td>{item.date}<br />{item.time}</td>
                                                        <td>
                                                            <Link to={'/order/' + item.id}><Eye className="btn-icon-show m-r-8" variant="Bulk" /></Link>
                                                            <Trash className="btn-icon-delete m-l-16" variant="Bulk" onClick={(e) => handleOrderDelete(item.id)} />
                                                            {/*{orderStatus === 'reject' ? <Trash className="btn-icon-delete m-l-16" variant="Bulk" onClick={(e) => handleOrderDelete(item.id)} /> : null}*/}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }


                {/* loading */}
                {orderDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>


            {
                orderData !== null && orderData.length > 0 ?
                    <div className="section-footer">
                        <Pagination data={paginationData} loadData={loadData} />
                    </div>
                    :
                    null
            }

            {/* popup delete */}
            <DialogQuestion ref={dialogQuestion} accept={orderDelete} title="Delete Order" des="Are you sure you want to delete the order?" buttonType="error" buttonAcceptText="Yes, Delete Order" buttonCloseText="No" />
        </div>
    );
}

export default Orders;
